export const baseUrl = 'https://universalportal-sso.qacitizensbank.com';
export const assetPrefix = "/header-mfe/";
export const assetURL = `${baseUrl}${assetPrefix}`;

export const environment = {
  production: false,
  isQA: false,
  isQA2: false,
  isSIT: false,
  isDEV: true,
  salutation: "Hello",
  salutationPrivateBanking: "Welcome",
  notificationsUrl: `${baseUrl}/notification/v1/notifications/viewList`
};