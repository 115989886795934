import { Component, Input } from '@angular/core';
import { assetURL, environment } from 'src/environments/environment';

@Component({
  selector: 'app-private-banking-header',
  templateUrl: './private-banking-header.component.html',
  styleUrls: ['./private-banking-header.component.css']
})
export class PrivateBankingHeaderComponent {
  @Input() fullName: string = "";
  @Input() fullNameFromSession: string = "";
  @Input() salutation: string = ''

  ngOnInit(){
    this.fullName = JSON.parse(JSON.stringify(sessionStorage.getItem('userDisplayName')))
    this.salutation = environment.salutation
  }
  

  get assetURL() {
    return assetURL;
  }
}
