import { Component, ViewChild } from '@angular/core';
import { DynamicComp } from './directives/dynamic-comp.directive';
import { HeaderComponent } from './components/header/header.component';
import { singleSpaPropsSubject } from 'src/single-spa/single-spa-props';
import { PrivateBankingHeaderComponent } from './components/private-banking-header/private-banking-header.component';

@Component({
  selector: 'app-header-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  currentCompName: string = "sso-header";
  @ViewChild(DynamicComp, { static: true }) compRef!: DynamicComp;

  compHash: { name: string, compRef: any, environmentDataKey: string }[] = [
    { name: "sso-header", compRef: HeaderComponent, environmentDataKey: "ssoHeaderLinks" },
    { name: "private-banking-header", compRef: PrivateBankingHeaderComponent, environmentDataKey: "ssoHeaderLinks" }

  ];

  constructor() {}

  ngOnInit() {
    this.injectComponent();
    singleSpaPropsSubject.subscribe((props: any) => {
      console.log('props are here', props.currentCompName)
      this.currentCompName = props.currentCompName;
      this.injectComponent();
    });
  }

  injectComponent() {
    if (!this.currentCompName) return;

    const componentObj = this.compHash.find(comp => comp.name === this.currentCompName);
    if (!componentObj) return;

    const viewRef = this.compRef.viewContainerRef;
    viewRef.clear();
    viewRef.createComponent(componentObj.compRef);
    // compRef.instance.links = environment[componentObj.environmentDataKey]
  }

}
